import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faTags,
} from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";

export default function RegioneProvidersPage({ data }) {
  const {
    regioniJson: regione,
    allProvidersJson: { nodes: providers },
  } = data;

  return (
    <Layout pageTitle={`Providers regione ${regione.descrizione}`}>
      <section className="position-relative">
        <StaticImage
          src="../../images/header-provider.jpg"
          className="img-header-provider"
          alt={`Providers regione ${regione.descrizione}`}
          title={`Providers regione ${regione.descrizione}`}
        />
        <div className="container-fluid px-0 py-5 container-absolute">
          <div className="container py-5">
            <h1 className="text-center text-white">
              Providers regione {regione.descrizione}
            </h1>
          </div>
        </div>
      </section>
      <div class="container-fluid my-5">
        <div class="row">
          {providers.map((provider) => (
            <div
              key={provider.id}
              className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0"
            >
              <div className="card card-provider border-secondary mb-3">
                <div className="card-header">
                  Provider N. {provider.id_provider} -{" "}
                  {provider.ragione_sociale}
                </div>
                <div className="card-body text-primary">
                  <p className="card-text text-center">
                    <span className="font-weight-bold">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />{" "}
                      {provider.sede_legale}
                      <br />
                      <FontAwesomeIcon icon={faTags} className="mr-1" />{" "}
                      Tipologia formazione: {provider.tipo_formazione}
                      <br />
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="mr-1"
                      />{" "}
                      Email:{" "}
                      <a
                        href={`mailto:${provider.email}`}
                        title={`Scrivi a ${provider.ragione_sociale}`}
                      >
                        {provider.email}
                      </a>
                      <br />
                      <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
                      Telefono:
                      <a
                        href={`tel:+39${provider.telefono}`}
                        title={`Telefona a ${provider.ragione_sociale}`}
                      >
                        {provider.telefono}
                      </a>
                    </span>
                  </p>
                  <Link to={provider.providerPath} className="btn btn-warning">
                    Vedi dettaglio
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($descrizione: String) {
    regioniJson(descrizione: { eq: $descrizione }) {
      id
      descrizione
    }
    allProvidersJson(filter: { regione: { eq: $descrizione } }) {
      nodes {
        id
        id_provider
        ragione_sociale
        sede_legale
        telefono
        email
        tipo_formazione
        providerPath: gatsbyPath(
          filePath: "/providers-agenas/{ProvidersJson.slug}"
        )
      }
    }
  }
`;
